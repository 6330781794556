<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap pt-5 px-5 px-sm-15 pt-sm-10>
      <v-layout wrap>
        <v-flex xs8 sm10 md10 lg10 text-left>
          <span style="color: #000; font-family: Montserrat-Bold; font-size: 25px"
            >User View</span
          >
        </v-flex>

        <v-flex xs4 sm2 md2 lg2 text-end>
          <v-btn color="#00A08E" @click="changeStatusDial()">
            <span
                    style="
                      font-family: Montserrat-Regular;
                      font-weight: 600;
                      color: white;
                    "
                    >Status</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-flex xs12 pt-5>
        <v-card>
          <v-layout wrap justify-start>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Name :
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md8 lg8 xl8 text-left v-if="user.name">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.name }}
                  </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Email :
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md8 lg8 xl8 text-left v-if="user.email">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.email }}
                  </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5 v-if="user.phone">
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Phone :
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md8 lg8 xl8 text-left v-if="user.phone">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.countryCode }} {{ user.phone }}
                  </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Role :
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.role">
                  <span style="color: black; font-family: poppinsmedium">{{ user.role }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Date :
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.create_date">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.create_date.slice(0, 10) }}
                  </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm3 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Sign-in :
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.role && user.signUpMethod">
                  <span style="color: black; font-family: poppinsmedium">{{ user.signUpMethod }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs5 sm4 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Plan Name : 
                  </span>
                </v-flex>
                <v-flex xs7 sm8 md9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.planName">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.planName }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs5 sm4 md2 lg2 xl2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Plan Type : 
                  </span>
                </v-flex>
                <v-flex xs7 sm8 md9 lg9 xl9 text-left v-if="user.role && userSubscriptionPlanId && userSubscriptionPlanId.planTypeName">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.planTypeName }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs5 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Plan Start Date : 
                  </span>
                </v-flex>
                <v-flex xs7 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.planStartDate">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.planStartDate.slice(0, 10) }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs5 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Plan End Date : 
                  </span>
                </v-flex>
                <v-flex xs9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.planEndDate">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.planEndDate.slice(0, 10) }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs5 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Payment Status : 
                  </span>
                </v-flex>
                <v-flex xs7 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.paymentStatus">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.paymentStatus }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Status : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.status">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.status }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Amount : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.currencySymbol">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.currencySymbol }}{{ user.userSubscriptionPlanId.amount }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Duration : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.duration">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.duration }} Days </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Description : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.description">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.description }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md3 lg3 xl3 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Trial Period : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md9 lg9 xl9 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.trialPeriod">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.trialPeriod }} Days </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md4 lg4 xl4 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Bulk Subscription : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md8 lg8 xl8 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.isBulkSubscription">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.isBulkSubscription ? "yes" : "No" }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs4 sm4 md4 lg4 xl4 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Bulk Main Account : 
                  </span>
                </v-flex>
                <v-flex xs8 sm8 md8 lg8 xl8 text-left v-if="user.role && user.userSubscriptionPlanId && user.userSubscriptionPlanId.isBulkMainAccount">
                  <span style="color: black; font-family: poppinsmedium">{{ user.userSubscriptionPlanId.isBulkMainAccount ? "yes" : "No" }} </span>
                </v-flex>

                <v-flex v-else>
                  <span>N/A</span>
                </v-flex>
              </v-layout>
              
            </v-flex>


          </v-layout>
          <!-- <v-layout wrap pt-5 px-5 v-if="course.length>0">
            <v-flex xs12>
              <span
                style="color: #000; font-family: Montserrat-Bold; font-size: 20px"
                >Courses</span
              >
              <v-layout wrap justify-center>
                <v-flex xs12 py-4 v-for="(item, i) in course" :key="i">
                  <v-card outlined>
                    <v-layout wrap justify-center pa-4>
                      <v-flex xs12 sm4 md2>
                        <v-img
                          :src="mediaURL + item.courseId.frontImage"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm8 md8 pa-4 text-left>
                        <v-layout wrap>
                          <v-flex xs12>
                            <span
                              style="
                                color: #7f7f7f;
                                font-family: poppinsregular;
                                font-size: 13px;
                              "
                              >Courses | {{ item.courseId.category.name }}</span
                            >
                          </v-flex>
                          <v-flex xs12 pt-1>
                            <span
                              style="
                                font-family: Montserrat-Regular; font-weight: 600;;
                                font-size: 14px;
                                color: #000000;
                              "
                              >{{ item.courseId.courseName }}</span
                            >
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 12px;
                                color: #000000;
                              "
                              v-html="item.courseId.description"
                            ></span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm2 md2 text-right align-self-center>
                        <v-btn color="#3CB043" dark :to="'/examData?userId=' + user._id"><span style="color:white">Exam Details</span></v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
  v-model="changeStatusDialog"
  width="400px"
  content-class="dialog-custom"
>
  <v-card>
    <v-layout wrap justify-center pa-8 pt-6>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-layout wrap justify-center>
          <v-flex xs11 sm11 md11 lg11 pb-3>
            <span
              style="
                font-family: Montserrat-Regular;
                font-size: 24px;
                font-weight: 700;
              "
            >
              Change Status
            </span>
          </v-flex>
          <v-flex xs1 sm1 md1 lg1>
            <v-btn icon @click="changeStatusDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>

          <!-- Status Dropdown -->
          <v-flex xs12 sm12 md12 lg12>
            <span
              style="
                font-family: Lato-Regular;
                font-size: 16px;
                font-weight: 500;
              "
            >
              Status
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 pt-1>
            <v-select
              v-model="status"
              :items="['Active', 'Inactive']"
              outlined
              dense
              placeholder="Select Status"
              hide-details="auto"
            ></v-select>
          </v-flex>

          <!-- Remarks Textarea -->
          <v-flex xs12 sm12 md12 lg12 pt-3 v-if="status === 'Inactive'">
            <span
              style="
                font-family: Lato-Regular;
                font-size: 16px;
                font-weight: 500;
              "
            >
              Remarks
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 pt-1 v-if="status === 'Inactive'">
            <v-textarea
              v-model="remarks"
              outlined
              dense
              placeholder="Enter remarks (optional)"
              rows="3"
              hide-details="auto"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
            <v-btn color="rgba(0, 160, 142, 1)" @click="changeStatus()">
              <span
                style="
                  color: white;
                  font-family: Montserrat-Regular;
                  font-size: 14px;
                  font-weight: 600;
                  text-transform: none;
                "
              >
                Submit
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</v-dialog>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      changeStatusDialog: false,
      statusOtions: ['Active', 'Inactive'],
      status: "",
      remarks: "",
      currentStatus: "",
      currentRemarks: "",
      user: {},
      course: [],
      id: this.$route.query.id,
    };
  },
  mounted() {
    this.getData(this.id);
    // this.getUserStatus();
  },
  methods: {
    getData(id) {
      this.appLoading = true;
      axios({
        url: '/admin/user/view/' + id,
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.currentStatus = response.data.data.status;
          this.currentRemarks = response.data.data.remarks;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    changeStatusDial(){
      this.status = this.currentStatus;
      this.remarks = this.currentRemarks;
      this.changeStatusDialog = true;
    },

    changeStatus() {
      if(this.status === "Active"){
        this.remarks = "";
      }
    axios({
      method: "POST",
      url: "/admin/user/activate/inactivate",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        id: this.id,
        status: this.status,
        remarks: this.remarks,
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.getData(this.id);
          this.changeStatusDialog = false;
        } else {
          this.msg = response.data.msg;
          this.showsnackbar = true;
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },

  getUserStatus() {
    axios({
      url: "/policy/view",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
      params: {
        type: "Terms and Conditions",
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.aboutlist.content = response.data.data.content; // ✅ Set existing content
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },

  },
};
</script>